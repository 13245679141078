import Constants from '../constants.js'
import AuthUtil from '../authUtil'
import FirestoreUtil from '../firestoreUtil'

import axios from 'axios'

import { query, limit, collection, where, getDocs } from 'firebase/firestore';

export default {
  usersDecisionsRef () {
    const currentUser = AuthUtil.auth.currentUser
    return query(collection(FirestoreUtil.db, 'decisions'), where("owner", "==", currentUser.uid))
  },
  sharedDecisionsRef () {
    const currentUser = AuthUtil.auth.currentUser
    return query(collection(FirestoreUtil.db, 'decisions'), where(`members.${currentUser.uid}.acceptedInvite`, "==", true))
  },
  async getDiscoverableDecisions (taxon, taxonRegion, scraperRegion, categoryTag, excludeUserId, limitSize) {
    let res = []
    
    const queryConstraints = [where(`isDiscoverable`, "==", true), where('owner', '!=', excludeUserId), where('taxonRegion', '==', taxonRegion), where('category.id', '==', taxon)]
    
    if (categoryTag) {
      queryConstraints.push(where(`categoryTags.${categoryTag}`, "==", true))
    }
    if (scraperRegion) {
      queryConstraints.push(where('scraperRegion', '==', scraperRegion))
    }

    const matchingDocsQuery = query(collection(FirestoreUtil.db, 'decisions'), ...queryConstraints, limit(limitSize))

    // const resultsSnapshot = await matchingDocsRef.get()
    const resultsSnapshot = await getDocs(matchingDocsQuery)
    if (!resultsSnapshot.empty) {
      resultsSnapshot.forEach((doc) => {
        res.push({
          id: doc.id,
          ... doc.data()
        })
      })
    }
    return res
  },
  async getInvitations () {
    let res = []
    const currentUser = AuthUtil.auth.currentUser
    const matchingDocsQuery = query(collection(FirestoreUtil.db, 'decisions'), where(`members.${currentUser.uid}.acceptedInvite`, "==", false), where(`members.${currentUser.uid}.rejectedInvite`, "==", false))

    // const resultsSnapshot = await matchingDocsRef.get()
    const resultsSnapshot = await getDocs(matchingDocsQuery)
    if (!resultsSnapshot.empty) {
      resultsSnapshot.forEach((doc) => {
        res.push({
          id: doc.id,
          ... doc.data()
        })
      })
    }
    return res
  },
  async updateInvitationState (payload) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const userInvitationsURL = `${Constants.API_BASE_PATH}/user/invitations/`
    const response = await axios.post(userInvitationsURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async getDecisionsMap () {
    const currentUser = AuthUtil.auth.currentUser
    const token = currentUser && (await currentUser.getIdToken())
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const getDecisionsMapURL = `${Constants.API_BASE_PATH}/decisionsmap`

    const res = await axios.get(getDecisionsMapURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      },
    })

    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      // TODO log error
      return null
    }
  },
  async createNewDecision (decisionName, taxonRegion, scraperRegion, criteria, valueRanges, imgSrc) {
    const currentUser = AuthUtil.auth.currentUser
    const token = currentUser && (await currentUser.getIdToken())
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const postNewDecisionsURL = `${Constants.API_BASE_PATH}/decisions`
    const addDefaultCriteriaToBoard = false
    const newDecisionBody = {name: decisionName, addDefaults: addDefaultCriteriaToBoard, taxonRegion: taxonRegion}
    if (scraperRegion) {
      newDecisionBody.scraperRegion = scraperRegion
    }

    if (criteria) {
      newDecisionBody.criteria = criteria
    }

    if (valueRanges) {
      newDecisionBody.criteriaValueRanges = valueRanges
    }

    if (imgSrc) {
      newDecisionBody.imgSrc = imgSrc
    }

    const res = await axios.post(postNewDecisionsURL, newDecisionBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      },
    })

    if (res && res.status === 200 && res.data && res.data.decisionId) {
      return res.data
    } else {
      // TODO log error
      return false
    }
  },
  async setDecisionCategory (decisionId, setCategoryPayload) {
    const currentUser = AuthUtil.auth.currentUser
    const token = currentUser && (await currentUser.getIdToken())
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const postCategoryURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/category`

    return await axios.post(postCategoryURL, setCategoryPayload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  },
  async getDecisionData (decisionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const decisionURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}`
    const res = await axios.get(decisionURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    });

    if (res.status >= 200 && res.status < 300) {
      return res.data
    } else {
      return null
    }
  },
  async getAppDecisionData (appId, taxonId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const decisionURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/decision`

    try {
      const res = await axios.get(decisionURL, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      });

      if (res.status >= 200 && res.status < 300) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      // This endpoint can return a 500 error in case there is no comparison board
      console.error(error);
      return null
    }
  }
}
